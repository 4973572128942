import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assetss/css/EditarPaciente.css";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Collapse from "@mui/material/Collapse";
import { IconButton, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import ModalError from "../modals/ModalError";
import crypto from "../Crypto";
import {
  ModeloGetUrlJson,
  ModeloDelete,
  ModeloPost,
  ModeloGetUrl,
} from "../ModeloCrud";
import { styled } from "@mui/material/styles";
import { formatNumberES, hasPermission } from "../utils/Utils";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FacebookCircularProgress from "../FacebookCircularProgress";
import ModalConfirmation from "../modals/ModalConfirmation";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import ModalInformation from "../modals/ModalInformation";
import ModalTarjetasCoeficiente from "../modals/ModalTarjetasCoeficiente";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import InsertUpdatePresupuesto from "../presupuestos/InsertUpdatePresupuesto";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import BackPage from "../BackPage";
import PacienteHeader from "./PacienteHeader";
import ModalFormaPago from "../modals/ModalFormaPago";
import { RenderReportPresupuestos } from "../presupuestos/RenderReportPresupuestos";
import { PrintButton } from "../PrintButton";
import { AddRounded, MonetizationOn } from "@mui/icons-material";
import PaidIcon from "@mui/icons-material/Paid";
import CircularProgress from "@mui/material/CircularProgress";
import CalculateIcon from "@mui/icons-material/Calculate";
import { getIsOnlyResult } from "react-bootstrap-typeahead/types/utils";

const PacientePresupuestos = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [submitOk, setSubmitOk] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [allPresupuestos, setAllPresupuestos] = useState(null);
  const [aprobados, setAprobados] = useState(null);
  const [provisorios, setProvisorios] = useState(null);
  const [allItems, setAllItems] = useState(null);
  const [allPagos, setAllPagos] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalTarjetasCoeficiente, setModalTarjetasCoeficiente] =
    useState(false);
  const [borrarId, setBorrarId] = useState(0);
  const [editarId, setEditarId] = useState(0);
  const [selected, setSelected] = React.useState([]);
  let [rowPresupuesto, setRowPresupuesto] = React.useState([]);
  let [presupuestoToPrint, setPresupuestoToPrint] = useState(null);
  let [itemsToPrint, setItemsToPrint] = useState(null);
  const [idToPrint, setIdToPrint] = useState(0);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [nuevoPresupuesto, setNuevoPresupuesto] = useState(false);
  const [editarPresupuesto, setEditarPresupuesto] = useState(false);
  const [editarFormaPago, setEditarFormaPago] = useState(false);
  const [updateOk, setUpdateOk] = useState(false);
  const componentRef = useRef();
  const promiseResolveRef = useRef(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const checkRefs = useRef([]);
  const [openRows, setOpenRows] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  useEffect(() => {
    buscarPresupuestos();
  }, []);

  function BorrarPresupuesto(presupuestoId) {
    setBorrarId(presupuestoId);
    setDisplayConfirmationModal(true);
    setModalMessage("Seguro quiere Borrar el Presupuesto ?");
  }

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    setModalMessage(null);
  };

  const hideInformationModal = () => {
    setModalInformationOpen(false);
    setModalMessage(null);
  };

  const hideModalTarjetasCoeficiente = () => {
    setModalTarjetasCoeficiente(false);
  };

  const handleNew = () => {
    setNuevoPresupuesto(true);
  };

  const handleTarjetasCoeficiente = () => {
    setModalTarjetasCoeficiente(true);
  };

  const handleToggleRow = (id) => {
    setOpenRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleSelectAll = () => {
    setSelectedItems(allItems);
  };

  const handleDeselectAll = () => {
    setSelectedItems([]);
  };

  const handleClickItem = (isChecked, row) => {
    if (isChecked) {
      setSelectedItems([...selectedItems, row]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item.id !== row.id));
    }
  };

  function handlePrint(id) {
    setIdToPrint(id);
    const tempPresupuestos = allPresupuestos.filter((p) => p.id === id);
    setPresupuestoToPrint(tempPresupuestos[0]);
    const tempItems = allItems.filter((i) => i.presupuesto_id === id);
    setItemsToPrint(tempItems);
  }

  function confirmOk() {
    if (modalMessage === "Seguro quiere Borrar el Presupuesto ?") {
      EliminarPresupuesto();
    } else {
      UpdateSaldo();
    }
  }

  async function UpdateSaldo() {
    hideConfirmationModal();
    setLoading(true);
    let result = await ModeloPost("presupuesto", editarId, "anular", null);
    setLoading(false);
    if (result.error === false) {
      buscarPresupuestos();
    } else {
      setModalMessage(
        result.mensaje != null
          ? result.mensaje
          : result.errormessage != null
          ? result.errormessage
          : "Error desconocido."
      );
      setModalErrorOpen(true);
    }
    setEditarId(0);
    setLoading(false);
  }

  async function EliminarPresupuesto() {
    hideConfirmationModal();
    if (borrarId > 0) {
      let result = await ModeloDelete("presupuesto", borrarId);

      if (result.error === false) {
        buscarPresupuestos();
      } else {
        setModalMessage(result.mensaje);
        setModalErrorOpen(true);
      }
    }
  }

  async function buscarPresupuestos() {
    let PacienteId = crypto.decryptDataStorage("currentpatient");
    if (PacienteId !== null && typeof PacienteId !== "undefined") {
      setLoading(true);
      let result = await ModeloGetUrlJson(
        "paciente",
        PacienteId,
        "vista-presupuestos-paciente-full",
        null
      );
      setLoading(false);

      if (result.error === false) {
        setAllPresupuestos(result.presupuestos);
        setAprobados(
          result.presupuestos.filter((p) => p.estado === "APROBADO")
        );
        setProvisorios(
          result.presupuestos
            .filter((p) => p.estado === "PROVISORIO")
            .sort((a, b) => (a.id > b.id ? -1 : 1))
        );
        setAllItems(result.presupuestos_lineas);
        setAllPagos(result.presupuestos_pagos);
        if (editarFormaPago) {
          const rowFP = result.presupuestos.filter(
            (p) => p.id === rowPresupuesto.id
          );
          setRowPresupuesto(rowFP[0]);
        }
      } else {
      }
    } else {
      handleClickVolver();
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: "2px",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const hideErrorModal = () => {
    setModalErrorOpen(false);
    setModalMessage(null);
  };

  function handleClickVolver() {
    if (!nuevoPresupuesto && !editarPresupuesto) {
      history.push("/pacientes");
    } else {
      hidePresupuesto();
    }
  }

  const hidePresupuesto = () => {
    setRowPresupuesto([]);
    setNuevoPresupuesto(false);
    setEditarPresupuesto(false);
  };

  const hideFormaPago = () => {
    setRowPresupuesto([]);
    setEditarFormaPago(false);
  };

  async function aprobar(presupuestoid, bonificacion) {
    try {
      let aprobados = selectedItems.filter(
        (nitem) => nitem.presupuesto_id === presupuestoid
      );
      if (aprobados.length === 0) {
        setModalMessage("Seleccione al menos un Item.");
        setModalInformationOpen(true);
        return;
      }
      let neto = 0;
      if (typeof bonificacion === "undefined" || bonificacion === null) {
        bonificacion = 0;
      }
      let bruto = aprobados.reduce(function (prev, cur) {
        return prev + parseFloat(cur.importe);
      }, 0);
      neto = Math.round(bruto - bruto * (bonificacion / 100), 2);
      let stitems = "";
      for (let i = 0; i < aprobados.length; i++) {
        stitems += "items[" + i + "]=" + aprobados[i].id + "&";
      }
      let user_id = crypto.decryptLocalStorage("user_id");
      let stencabezado =
        "importe_bruto=" +
        bruto +
        "&importe_neto=" +
        neto +
        "&user_id=" +
        user_id +
        "&bonificacion=" +
        bonificacion;
      setLoadingItems(true);
      let result = await ModeloPost(
        "presupuesto",
        presupuestoid,
        "aprobar",
        stitems + stencabezado
      );
      setLoadingItems(false);
      console.log("Resultado", result);
      if (result.error === false) {
        buscarPresupuestos();
      } else {
        setModalMessage(result.errormessage);
        setModalErrorOpen(true);
      }
    } catch (e) {
      setLoadingItems(false);
      setModalMessage(e.message);
      setModalErrorOpen(true);
    }
  }

  async function restaurar(presupuestoid) {
    setLoadingItems(true);
    let result = await ModeloGetUrl(
      "presupuesto",
      presupuestoid,
      "ordenes",
      null
    );
    if (result.error === false) {
      if (result.listado.length > 0) {
        setLoadingItems(false);
        setModalMessage(
          "Presupuesto con Ordenes Emitidas. No se puede Restaurar."
        );
        setModalInformationOpen(true);
        return;
      } else {
        let result = await ModeloPost(
          "presupuesto",
          presupuestoid,
          "restaurar",
          null
        );
        setLoadingItems(false);
        if (result.error === false) {
          buscarPresupuestos();
        } else {
          setModalMessage(result.mensaje);
          setModalErrorOpen(true);
        }
      }
    } else {
      setLoadingItems(false);
      setModalMessage(result.mensaje);
      setModalErrorOpen(true);
    }
  }

  async function UpdatePrecios(presupuestoid) {
    setLoadingItems(true);
    let result = await ModeloPost(
      "presupuesto",
      presupuestoid,
      "actualizar-importe-lineas",
      null
    );
    setLoadingItems(false);
    if (result.error === true) {
      setModalMessage(result.mensaje);
      setModalErrorOpen(true);
    }
    buscarPresupuestos();
  }

  const handleEdit = (row) => {
    setRowPresupuesto(row);
    setEditarPresupuesto(true);
  };

  const handleEditFormaPago = (row) => {
    setRowPresupuesto(row);
    setEditarFormaPago(true);
  };

  const anularSaldo = (presupuestoid, valor) => {
    setEditarId(presupuestoid);
    setDisplayConfirmationModal(true);
    setModalMessage(
      valor === 1
        ? "Seguro quiere Restaurar el Saldo del Presupuesto ?"
        : "Seguro quiere Anular el Saldo del Presupuesto ?"
    );
  };

  function Row(props) {
    const { row } = props;
    const isItemSelected = (rowId) => {
      return selectedItems.some((item) => item.id === rowId);
    };

    return (
      <React.Fragment>
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell align="right">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleToggleRow(row.id)}
            >
              {openRows[row.id] ? (
                <i
                  class={`fa-solid fa-circle-chevron-up fa-xl ${
                    row.estado === "APROBADO"
                      ? " text-green-500"
                      : " text-red-500"
                  }`}
                ></i>
              ) : (
                <i
                  class={`fa-solid fa-circle-chevron-down fa-xl ${
                    row.estado === "APROBADO"
                      ? " text-green-500"
                      : " text-red-500"
                  }`}
                ></i>
              )}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell align="left" component="th" scope="row">
            <div className="flex justify-between">
              <div>
                <div style={{ display: "none" }}>
                  <RenderReportPresupuestos
                    ref={componentRef}
                    presupuesto={presupuestoToPrint}
                    items={itemsToPrint}
                    id={idToPrint}
                  />
                </div>
              </div>
              <div>{row.id}</div>
            </div>
          </StyledTableCell>
          <StyledTableCell align="left">{row.fecha_em_arg}</StyledTableCell>
          <StyledTableCell align="left">
            <Box
              sx={{
                textTransform: "capitalize",
              }}
            >
              {row.odontologo.toLowerCase()}
            </Box>
          </StyledTableCell>
          <StyledTableCell align="right">
            {formatNumberES(row.bonificacion, 2)}
          </StyledTableCell>
          <StyledTableCell align="right">
            {formatNumberES(row.importe_neto, 2)}
          </StyledTableCell>
          <StyledTableCell align="left">{row.prepaga_codigo}</StyledTableCell>
          <StyledTableCell align="left">
            <Box
              sx={{
                textTransform: "capitalize",
              }}
            >
              {row.prepaga_razon.toLowerCase()}
            </Box>
          </StyledTableCell>
          <StyledTableCell align="right">{row.pagado}</StyledTableCell>
          <StyledTableCell align="right">
            {row.saldo !== null &&
              row.saldo > 0 &&
              hasPermission(userRoles, "ANULAR PRESUPUESTOS") && (
                <MonetizationOn
                  cursor="pointer"
                  sx={{
                    color: "#ef4444",
                    fontSize: 24,
                    mr: { sm: 1 },
                    ml: { xs: -3, sm: -2 },
                  }}
                  onClick={() => anularSaldo(row.id, row.saldo_anulado)}
                />
              )}
            {row.saldo_anulado !== null &&
              row.saldo_anulado > 0 &&
              hasPermission(userRoles, "ANULAR PRESUPUESTOS") && (
                <Tooltip title="Saldo Anulado">
                  <MoneyOffIcon
                    cursor="pointer"
                    sx={{
                      color: "#ef4444",
                      fontSize: 24,
                      mr: { sm: 1 },
                      ml: { xs: -3, sm: -2 },
                    }}
                    onClick={() => anularSaldo(row.id, row.saldo_anulado)}
                  />
                </Tooltip>
              )}
            {formatNumberES(row.saldo, 2)}
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography sx={{ fontFamily: "Roboto", fontSize: 12 }}>
              {row.observaciones}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography sx={{ fontFamily: "Roboto", fontSize: 12 }}>
              {row.forma_pago}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Tooltip title="Forma de Pago">
              <PaidIcon
                cursor="pointer"
                sx={{
                  color: "#9333ea",
                  fontSize: 24,
                }}
                onClick={() => handleEditFormaPago(row)}
              />
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell align="left">
            {(hasPermission(userRoles, "APROBAR PRESUPUESTOS") ||
              (hasPermission(userRoles, "MODIFICACION CPRESUPUESTOS") &&
                row.estado === "PROVISORIO")) && (
              <EditIcon
                cursor="pointer"
                sx={{
                  color: "primary.main",
                  fontSize: 24,
                }}
                onClick={() => handleEdit(row)}
              />
            )}
          </StyledTableCell>
          {row.estado === "PROVISORIO" && (
            <StyledTableCell align="left">
              {hasPermission(userRoles, "BAJA CPRESUPUESTOS") && (
                <DeleteIcon
                  cursor="pointer"
                  sx={{
                    color: "primary.red",
                    fontSize: 24,
                  }}
                  onClick={() => BorrarPresupuesto(row.id)}
                />
              )}
            </StyledTableCell>
          )}
          <StyledTableCell align="left">
            {hasPermission(userRoles, "IMPRIMIR PRESUPUESTOS") && (
              <PrintButton
                componentToPrint={componentRef}
                promiseResolveRef={promiseResolveRef}
                beforePrint={() => handlePrint(row.id)}
                buttonType={"table"}
                isPrinting={isPrinting}
                setIsPrinting={setIsPrinting}
              />
            )}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={openRows[row.id]} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  ml: 2,
                  mb: -1,
                  borderBottom: 1,
                  borderColor: "grey.400",
                  p: 1,
                  gap: 2,
                }}
              >
                {row.estado === "PROVISORIO" &&
                  hasPermission(userRoles, "APROBAR PRESUPUESTOS") && (
                    <>
                      <Button
                        variant="outlined"
                        startIcon={<CheckCircleIcon />}
                        onClick={() => aprobar(row.id, row.bonificacion)}
                      >
                        Aprobar
                      </Button>
                      <Button
                        variant="outlined"
                        startIcon={<MonetizationOn />}
                        onClick={() => UpdatePrecios(row.id)}
                      >
                        Actualizar
                      </Button>
                      {loadingItems && (
                        <div className="mt-1">
                          <CircularProgress size="1.75rem" />
                        </div>
                      )}
                    </>
                  )}
                {row.estado === "APROBADO" && (
                  <>
                    {hasPermission(userRoles, "RESTAURAR PRESUPUESTOS") && (
                      <Button
                        variant="outlined"
                        startIcon={<UnpublishedIcon />}
                        onClick={() => restaurar(row.id)}
                      >
                        Restaurar
                      </Button>
                    )}
                    {loadingItems && (
                      <div className="mt-1">
                        <CircularProgress size="1.75rem" />
                      </div>
                    )}
                  </>
                )}
              </Box>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="items">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        {row.estado === "PROVISORIO" &&
                          hasPermission(userRoles, "APROBAR PRESUPUESTOS") && (
                            <Checkbox
                              checked={selectedItems.length === allItems.length}
                              onChange={(event) =>
                                event.target.checked
                                  ? handleSelectAll()
                                  : handleDeselectAll()
                              }
                            />
                          )}
                      </TableCell>

                      <TableCell align="right">Id</TableCell>
                      <TableCell align="left">Alt.</TableCell>
                      <TableCell align="right">Ap.</TableCell>
                      <TableCell align="left">Código</TableCell>
                      <TableCell align="left">Descripción</TableCell>
                      <TableCell align="left">Pieza</TableCell>
                      <TableCell align="left">Caras</TableCell>
                      <TableCell align="right">Importe</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allItems !== null &&
                      allItems
                        .filter((p) => p.presupuesto_id === row.id)
                        .sort((a, b) => (a.aprobado > b.aprobado ? -1 : 1))
                        .map((rowi, index) => (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected(rowi.id)}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected(rowi.id)}
                          >
                            <TableCell padding="checkbox">
                              {row.estado === "PROVISORIO" &&
                                hasPermission(
                                  userRoles,
                                  "APROBAR PRESUPUESTOS"
                                ) && (
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      checked={isItemSelected(rowi.id)}
                                      onChange={(event) =>
                                        handleClickItem(
                                          event.target.checked,
                                          rowi
                                        )
                                      }
                                      sx={{ cursor: "pointer" }}
                                    />
                                  </TableCell>
                                )}
                            </TableCell>
                            <TableCell
                              align="right"
                              component="th"
                              scope="row"
                              sx={{
                                color:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "#cbd5e1"
                                    : "#123564",
                                textDecoration:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "line-through"
                                    : "none",
                              }}
                            >
                              {rowi.id}
                            </TableCell>
                            <TableCell
                              sx={{
                                color:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "#cbd5e1"
                                    : "#123564",
                                textDecoration:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "line-through"
                                    : "none",
                              }}
                              align="left"
                            >
                              {rowi.alternativa}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                color:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "#cbd5e1"
                                    : "#123564",
                                textDecoration:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "line-through"
                                    : "none",
                              }}
                            >
                              {rowi.aprobado}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                color:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "#cbd5e1"
                                    : "#123564",
                                textDecoration:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "line-through"
                                    : "none",
                              }}
                            >
                              {rowi.codigo_nomenclador}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                color:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "#cbd5e1"
                                    : "#123564",
                                textDecoration:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "line-through"
                                    : "none",
                              }}
                            >
                              <Box
                                sx={{
                                  textTransform: "capitalize",
                                  color:
                                    rowi.aprobado <= 0 &&
                                    row.estado === "APROBADO"
                                      ? "#cbd5e1"
                                      : "#123564",
                                  textDecoration:
                                    rowi.aprobado <= 0 &&
                                    row.estado === "APROBADO"
                                      ? "line-through"
                                      : "none",
                                }}
                              >
                                {rowi.descripcion_nomenclador.toLowerCase()}
                              </Box>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                color:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "#cbd5e1"
                                    : "#123564",
                                textDecoration:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "line-through"
                                    : "none",
                              }}
                            >
                              {rowi.numero_pieza_dental}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                color:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "#cbd5e1"
                                    : "#123564",
                                textDecoration:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "line-through"
                                    : "none",
                              }}
                            >
                              {rowi.caras}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                color:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "#cbd5e1"
                                    : "#123564",
                                textDecoration:
                                  rowi.aprobado <= 0 &&
                                  row.estado === "APROBADO"
                                    ? "line-through"
                                    : "none",
                              }}
                            >
                              {formatNumberES(rowi.importe, 2)}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <BackPage
        text={
          !nuevoPresupuesto && !editarPresupuesto
            ? "Volver al Paciente"
            : "Volver a Presupuestos"
        }
        handleClick={handleClickVolver}
      />
      <PacienteHeader />

      <div className="flex justify-center mt-2">
        {loading && <FacebookCircularProgress />}
      </div>
      {!loading && !nuevoPresupuesto && !editarPresupuesto && (
        <Container maxWidth="xl">
          <Box sx={{ mr: 2, ml: 2, mb: 4 }}>
            <Typography
              sx={{
                fontSize: 22,
                marginTop: 0,
                marginRight: 2,
                marginBottom: 2,
              }}
            >
              Presupuestos Aprobados
            </Typography>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Items</StyledTableCell>
                    <StyledTableCell align="right">Id</StyledTableCell>
                    <StyledTableCell align="left">Fecha</StyledTableCell>
                    <StyledTableCell align="left">Odontólogo</StyledTableCell>
                    <StyledTableCell align="right">Bonif.</StyledTableCell>
                    <StyledTableCell align="right">Importe</StyledTableCell>
                    <StyledTableCell align="left">Empresa</StyledTableCell>
                    <StyledTableCell align="left">Razón</StyledTableCell>
                    <StyledTableCell align="right">Pagado</StyledTableCell>
                    <StyledTableCell align="right">Saldo</StyledTableCell>
                    <StyledTableCell align="left">Nota</StyledTableCell>
                    <StyledTableCell align="left">F.Pago</StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {aprobados !== null &&
                    aprobados.length > 0 &&
                    aprobados
                      .sort((a, b) =>
                        a.fecha_emsion > b.fecha_emsion ? -1 : 1
                      )
                      .map((row) => <Row key={row.id} row={row} />)}
                  {aprobados !== null && aprobados.length === 0 && (
                    <Box sx={{ ml: 2, mt: 2, mb: 2 }}>
                      {" "}
                      <Typography variant="body1" gutterBottom>
                        Sin Presupuestos.
                      </Typography>
                    </Box>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ display: "flex", marginTop: 2, marginBottom: 1 }}>
              <Typography sx={{ fontSize: 22, marginTop: 0, marginRight: 2 }}>
                Presupuestos Provisorios
              </Typography>
              {hasPermission(userRoles, "ALTA CPRESUPUESTOS") && (
                <IconButton
                  aria-label="new"
                  onClick={handleNew}
                  sx={{ p: "1px", marginTop: -1 }}
                >
                  <AddRounded sx={{ fontSize: 48, color: "icongreen" }} />
                </IconButton>
              )}
              <IconButton
                aria-label="calculator"
                onClick={handleTarjetasCoeficiente}
                sx={{ p: "1px", marginTop: -1 }}
              >
                <CalculateIcon sx={{ fontSize: 48, color: "#7c3aed" }} />
              </IconButton>
            </Box>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Items</StyledTableCell>
                    <StyledTableCell align="right">Id</StyledTableCell>
                    <StyledTableCell align="left">Fecha</StyledTableCell>
                    <StyledTableCell align="left">Odontólogo</StyledTableCell>
                    <StyledTableCell align="right">Bonif.</StyledTableCell>
                    <StyledTableCell align="right">Importe</StyledTableCell>
                    <StyledTableCell align="left">Empresa</StyledTableCell>
                    <StyledTableCell align="left">Razón</StyledTableCell>
                    <StyledTableCell align="right">Pagado</StyledTableCell>
                    <StyledTableCell align="right">Saldo</StyledTableCell>
                    <StyledTableCell align="left">Nota</StyledTableCell>
                    <StyledTableCell align="left">F.Pago</StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {provisorios !== null &&
                    provisorios.length > 0 &&
                    provisorios.map((row) => <Row key={row.id} row={row} />)}
                  {provisorios !== null && provisorios.length === 0 && (
                    <Box sx={{ ml: 2, mt: 2, mb: 2 }}>
                      <Typography variant="body1" gutterBottom>
                        Sin Presupuestos.
                      </Typography>
                    </Box>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      )}
      {nuevoPresupuesto && (
        <InsertUpdatePresupuesto
          presupuestorow={null}
          itemspresupuesto={null}
          buscarCoes={true}
          showModal={nuevoPresupuesto}
          hideModal={hidePresupuesto}
          setLoading={setLoading}
          setUpdateOk={setUpdateOk}
          buscarPresupuestos={buscarPresupuestos}
        />
      )}
      {editarPresupuesto && (
        <InsertUpdatePresupuesto
          presupuestorow={rowPresupuesto}
          itemspresupuesto={allItems.filter(
            (i) => i.presupuesto_id === rowPresupuesto.id
          )}
          buscarCoes={true}
          showModal={nuevoPresupuesto}
          hideModal={hidePresupuesto}
          setLoading={setLoading}
          setUpdateOk={setUpdateOk}
          buscarPresupuestos={buscarPresupuestos}
        />
      )}
      <BackPage
        text={
          !nuevoPresupuesto && !editarPresupuesto
            ? "Volver al Paciente"
            : "Volver a Presupuestos"
        }
        handleClick={handleClickVolver}
      />

      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideErrorModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalConfirmation
        showModal={displayConfirmationModal}
        confirmModal={confirmOk}
        hideModal={hideConfirmationModal}
        message={modalMessage}
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      <ModalTarjetasCoeficiente
        showModal={modalTarjetasCoeficiente}
        hideModal={hideModalTarjetasCoeficiente}
      />
      {editarFormaPago && (
        <ModalFormaPago
          row={rowPresupuesto}
          allPagos={allPagos}
          showModal={editarFormaPago}
          hideModal={hideFormaPago}
          buscarPresupuestos={buscarPresupuestos}
        />
      )}
    </>
  );
};
export default PacientePresupuestos;
